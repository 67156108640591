import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from 'environments/environment';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { CoreModule } from 'app/core/core.module';
import { ApiInterceptor } from 'app/core/interceptors/api.interceptor';
import { StartupService } from 'app/core/services/startup.service';
import { InitComponent } from 'app/init.component';
import { OfflineComponent } from 'app/offline.component';

registerLocaleData(localeFr, 'fr');

/**
 * TranslateLoader to use webpack since the file is now typescript instead of json.
 */
export class WebpackTranslateLoader implements TranslateLoader {
  /**
   * Get the language translations.
   *
   * @param lang The language.
   * @returns An observable containing the translations.
   */
  public getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.ts`)).pipe(map(f => f.translations));
  }
}

/**
 * App module for the public services portal app
 */
@NgModule({
  declarations: [
    AppComponent,
    InitComponent,
    OfflineComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    CoreModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: canActivateApp,
      multi: true,
      deps: [StartupService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: environment.supportedLocales
      }
    },
    provideEnvironmentNgxMask({
      validation: false,
    }),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }

/**
 * Initializes the app once the startup service has finished setting up.
 *
 * @param startupService the application startup service.
 */
export function canActivateApp(startupService: StartupService): () => Promise<any> {
  return (): Promise<any> => startupService.setup();
}
