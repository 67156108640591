import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { buildRequestQueryParameter } from 'app/core/helpers/build-request-query-parameter.helper';
import { CreateStatusCertificateRequestModel, StatusCertificateBuildingDetailsModel, StatusCertificatePaymentDetailsModel } from 'app/core/models/status-certificate-details.model';
import { StateService } from 'app/core/services/state.service';

/**
 * Service for status certificate api requests
 */
@Injectable({
  providedIn: 'root'
})
export class StatusCertificateService {
  private _apiPrefix = 'api/v1/status-certificate';

  constructor(
    private _http: HttpClient,
    private _stateService: StateService
  ) { }

  /**
   * Gets the building suites and details
   */
  public getBuildingDetails(): Observable<StatusCertificateBuildingDetailsModel> {
    const params = new HttpParams({ fromObject: buildRequestQueryParameter({ clientToken: this._stateService.clientToken }) });
    return this._http
      .get<StatusCertificateBuildingDetailsModel>(`${this._apiPrefix}/building`, { params })
      .pipe(map((r: any) => r.data));
  }

  /**
   * Create a status certificate request
   */
  public requestCertificate(request: CreateStatusCertificateRequestModel): Observable<void> {
    const params = new HttpParams({ fromObject: buildRequestQueryParameter({ clientToken: this._stateService.clientToken }) });

    return this._http.post<any>(`${this._apiPrefix}`, request, {params});
  }

  /**
   * Gets the status certificate payment details
   */
  public getStatusCertificatePaymentDetails(): Observable<StatusCertificatePaymentDetailsModel> {
    const params = new HttpParams({ fromObject: buildRequestQueryParameter({ clientToken: this._stateService.clientToken }) });
    return this._http
      .get<StatusCertificatePaymentDetailsModel>(`${this._apiPrefix}`, { params })
      .pipe(map((r: any) => r.data));
  }

  /**
   * Confirm the payment for a status certificate
   */
  public confirmPayment(confirmationToken: string): Observable<boolean> {
    const params = new HttpParams({ fromObject: buildRequestQueryParameter({ clientToken: this._stateService.clientToken }) });

    return this._http.post<any>(`${this._apiPrefix}/payment`, { confirmationToken }, { params });
  }
};
