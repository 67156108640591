// This file can be replaced during build by using the `fileReplacements` array.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: false,
  local: false,
  publicServicesApi: '',
  supportedLocales: ['en', 'fr'],
  stripePublishableKey: 'pk_live_51KxvhcFxxta1oHe0oE7I4NyF9lypkZbEiPhFVh1hfyXsSKwi4fW7GtlI1pJzSq4jtwykGhOOgeax2M3bthUsyxwt002QxsEulN'
};

